import {createElement, forwardRef, useEffect} from "react";

import classNames from "~/utils/classNames";

export function TabLink({as = "a", className, active, children, ...props}) {
  return createElement(
    as,
    {
      ...props,
      className: [
        className,
        "border-b-2 px-3 py-2 font-medium hover:text-gray-900 dark:hover:text-white",
        active
          ? "border-brand-500 text-gray-900 dark:text-white"
          : "border-white dark:border-gray-800 hover:border-brand-500 text-gray-700 dark:text-gray-300",
      ]
        .filter(Boolean)
        .join(" "),
    },
    children,
  );
}

export default forwardRef(function Card(
  {
    as = "div",
    titleAs = "div",
    titleClassName,
    cover,
    header,
    children,
    footer,
    tabs,
    title,
    actions,
    extra,
    className,
    headerClassName = null,
    footerClassName = null,
    headerAlwaysFlex = false,
    bodyClassName,
    size = "default",
    divided = true,
    shadow = true,
    bordered = true,
    overflow = false,
    padded = true,
    flexTitle = false,
    background = "bg-white dark:bg-gray-800",
    ...props
  },
  ref,
) {
  const Wrapper = as;
  const TitleWrapper = titleAs;
  const sectionClassName = padded ? {small: "px-2 py-3 sm:px-4"}[size] || "px-4 py-5 sm:px-6 lg:px-8" : null;

  const hash = window.location.hash;

  useEffect(() => {
    if (hash === `#${props.id}`) {
      const element = document.getElementById(props.id);
      if (!element) return;
      element.classList.add("scroll-mt-20");
      element.classList.add("transition-transform");
      element.scrollIntoView({behavior: "smooth", block: "start"});
      element.classList.add("scale-105");
      setTimeout(() => {
        element.classList.remove("scale-105");
      }, 800);
    }
  }, [props.id, hash]);

  return (
    <Wrapper
      {...props}
      ref={ref}
      className={classNames(
        background,
        "flex flex-col justify-between relative border-gray-200 dark:border-gray-700 sm:rounded-lg",
        divided ? "divide-y divide-gray-200 dark:divide-gray-700" : null,
        bordered ? "border-transparent dark:border dark:border-gray-700" : null,
        shadow ? "shadow" : null,
        overflow ? null : "overflow-clip",
        className,
      )}
    >
      {cover ? <div>{cover}</div> : null}
      {header || tabs || title || extra ? (
        <TitleWrapper className={classNames(sectionClassName, titleClassName)}>
          <div
            className={flexTitle ? "flex items-center justify-between" : "md:flex md:items-center md:justify-between"}
          >
            <div className="flex-1 min-w-0">
              {title ? (
                <h2 className={classNames("leading-6 font-medium text-gray-900 dark:text-gray-100", headerClassName)}>
                  {title}
                </h2>
              ) : null}
              {header}
            </div>
            {extra ? (
              <div
                className={classNames(
                  flexTitle ? null : "mt-4",
                  "shrink-0 flex md:mt-0 md:ml-4 text-gray-900 dark:text-gray-100",
                )}
              >
                {extra}
              </div>
            ) : null}
          </div>
          {tabs ? (
            <div className="-mb-5 flex shrink-0 flex-wrap space-x-4 justify-center lg:justify-start">
              {tabs.map((tab) => (
                <TabLink key={tab.key} {...tab} />
              ))}
            </div>
          ) : null}
        </TitleWrapper>
      ) : null}
      {children ? (
        <div className={classNames("flex-grow card-content", sectionClassName, bodyClassName)}>{children}</div>
      ) : null}
      {actions ? (
        <div className="-mt-px flex shrink-0 divide-x divide-gray-200 dark:divide-gray-700">
          {actions.map(({key, as = "button", className, ...props}, idx) => (
            <div className={classNames(idx === 0 ? null : "-ml-px", "w-0 flex-1 flex text-center")} key={key}>
              {createElement(as, {
                ...props,
                className: classNames(
                  size === "small" ? "py-2 px-1" : "py-4 px-2",
                  "overflow-ellipsis -mr-px w-0 flex-1 inline-flex items-center justify-center text-xs text-brand-500 font-medium hover:text-brand-600",
                  className,
                ),
              })}
            </div>
          ))}
        </div>
      ) : footer ? (
        <div className={classNames("shrink-0", sectionClassName, footerClassName)}>{footer}</div>
      ) : null}
    </Wrapper>
  );
});
