import {useMemo, useState} from "react";

import {ticketBuyerOrganiserEventsUrl} from "~/apiRoutes";
import ErrorResponse from "~/components/ErrorResponse";
import OrganiserEventsList from "~/components/OrganiserEventsList";
import Pagination from "~/components/Pagination";
import fetchJsonApi from "~/fetchers/fetchJsonApi";
import useBackendData from "~/hooks/useBackendData";

export default function WidgetOrganiserList({
  inline = false,
  domain,
  color,
  hideBanners,
  whitelabel,
  noDirectSales,
  includePrivate,
  theme,
  organiserId,
  widgetId,
  thirdParty = false,
}) {
  const [page, setPage] = useState(1);
  const query = useMemo(() => {
    const params = new URLSearchParams();
    params.set("page[size]", "5");
    params.set("page[number]", page.toString());
    params.append("filter[kind][]", "regular");
    params.append("filter[kind][]", "subscription");
    params.append("filter[kind][]", "scheduled");
    params.append("filter[include_private]", includePrivate ? "1" : "0");
    return params;
  }, [page, includePrivate]);
  const {
    data: events,
    meta,
    error,
  } = useBackendData(ticketBuyerOrganiserEventsUrl({organiserId, query, domain}), {
    fetcher: fetchJsonApi,
    keepPreviousData: true,
  });
  const {pageSize, total} = meta;

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap sm:flex-nowrap gap-4 justify-center  rounded-lg bg-gray-50 dark:bg-gray-800">
        <div className="grow min-w-[200px] bg-gray-50 dark:bg-gray-800 rounded-lg transition-colors duration-300 p-5 font-medium text-gray-900 dark:text-white flex flex-col justify-center items-center gap-8 sm:gap-3">
          {error ? <ErrorResponse inline error={error} /> : null}
          <OrganiserEventsList
            inline={inline}
            domain={domain}
            color={color}
            whitelabel={whitelabel}
            theme={theme}
            events={events}
            noDirectSales={noDirectSales}
            hideBanners={hideBanners}
            widgetId={widgetId}
            thirdParty={thirdParty}
          />
          {total && total > pageSize ? (
            <Pagination simple pageSize={pageSize} size="xs" total={total} page={page} onChange={setPage} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
