import useSWR from "swr";

const emptyMetaData = {};
Object.freeze(emptyMetaData);

export default function useBackendData(key, options) {
  const {data, error, isLoading, isValidating, mutate} = useSWR(key, options);
  return {
    data: data?.response,
    meta: data?.meta ?? emptyMetaData,
    links: data?.links ?? emptyMetaData,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}
