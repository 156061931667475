import Card from "~/components/Card";
import WidgetOrganiserCalendar from "~/components/WidgetOrganiserCalendar";
import BillettoLogo from "~/icons/BillettoLogo";

export default function OrganiserCalendar({
  inline = false,
  color,
  organization,
  hideBanners,
  whitelabel,
  includePrivate,
  theme,
  organiserId,
  noDirectSales,
  widgetId,
  thirdParty = false,
}) {
  return (
    <div className="space-y-1 grow">
      <Card bordered={!inline} shadow={!inline} background="bg-white dark:bg-gray-900">
        <div className="space-y-4">
          <WidgetOrganiserCalendar
            inline={inline}
            organiserId={organiserId}
            domain={organization}
            color={color}
            whitelabel={whitelabel}
            includePrivate={includePrivate}
            theme={theme}
            hideBanners={hideBanners}
            noDirectSales={noDirectSales}
            widgetId={widgetId}
            thirdParty={thirdParty}
          />
        </div>
      </Card>
      {whitelabel ? null : (
        <div className="text-right text-gray-900 dark:text-white">
          <BillettoLogo className="inline h-4 w-auto" />
        </div>
      )}
    </div>
  );
}
