import {addMonths, isSameDay} from "date-fns";
import {fromZonedTime, toZonedTime} from "date-fns-tz";
import {useCallback, useEffect, useMemo, useState} from "react";

import {ticketBuyerOrganiserEventsUrl} from "~/apiRoutes";
import Button from "~/components/Button";
import ErrorResponse from "~/components/ErrorResponse";
import OrganiserEventsList from "~/components/OrganiserEventsList";
import Pagination from "~/components/Pagination";
import Spinner from "~/components/Spinner";
import fetchJsonApi from "~/fetchers/fetchJsonApi";
import useBackendData from "~/hooks/useBackendData";
import MonthlyCalendar from "~/MonthlyCalendar";
import getTimeZone from "~/utils/getTimeZone";

export default function WidgetOrganiserCalendar({
  inline = false,
  domain,
  color,
  whitelabel,
  includePrivate,
  noDirectSales,
  hideBanners,
  theme,
  organiserId,
  widgetId,
  thirdParty = false,
}) {
  const timeZone = getTimeZone();
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [page, setPage] = useState(1);
  const query = useMemo(() => {
    const params = new URLSearchParams();
    if (day) {
      params.set("filter[day]", day.toISOString());
    }
    params.set("page[size]", "3");
    params.set("page[number]", page.toString());
    params.append("filter[kind][]", "regular");
    params.append("filter[kind][]", "subscription");
    params.append("filter[kind][]", day ? "recurring" : "scheduled");
    params.append("filter[include_private]", includePrivate ? "1" : "0");
    return params;
  }, [day, includePrivate, page]);
  const facetsQuery = useMemo(() => {
    const params = new URLSearchParams();
    params.set("page[size]", "0");
    params.append("filter[kind][]", "regular");
    params.append("filter[kind][]", "subscription");
    params.append("filter[kind][]", "recurring");
    return params;
  }, []);
  const {
    meta: {facets: organiserFacets},
  } = useBackendData(ticketBuyerOrganiserEventsUrl({organiserId, domain, query: facetsQuery}), {
    fetcher: fetchJsonApi,
    keepPreviousData: true,
    revalidateOnFocus: false,
  });
  const initialMonth = useMemo(() => {
    if (organiserFacets?.date) {
      const date = organiserFacets.date.find(({date, count}) => count > 0 && new Date(date) >= new Date())?.date;
      return date ? new Date(date) : toZonedTime(new Date(), timeZone);
    }
  }, [organiserFacets, timeZone]);
  const {
    data: events,
    meta,
    error,
  } = useBackendData(ticketBuyerOrganiserEventsUrl({organiserId, query, domain}), {
    fetcher: fetchJsonApi,
    keepPreviousData: true,
  });
  const {pageSize, total} = meta;
  const onNextMonthClick = useCallback(() => {
    setDay(null);
    setMonth(addMonths(month, 1));
  }, [month]);
  const onPrevMonthClick = useCallback(() => {
    setDay(null);
    setMonth(addMonths(month, -1));
  }, [month]);
  const availableDates = useMemo(
    () => organiserFacets?.date.map((facet) => toZonedTime(new Date(facet.date), timeZone)) ?? [],
    [organiserFacets, timeZone],
  );
  useEffect(() => {
    if (!month && initialMonth) setMonth(initialMonth);
  }, [initialMonth, month]);
  useEffect(() => setPage(1), [day, month]);

  return (
    <div className="space-y-4 @container">
      {error ? <ErrorResponse inline error={error} /> : null}
      {events && month ? (
        <div className="flex flex-wrap @lg:flex-nowrap gap-4 rounded-lg justify-center bg-gray-50 dark:bg-gray-800 items-center">
          <div className="w-full @lg:order-2 max-w-[280px] bg-gray-100 dark:bg-gray-700 rounded-lg transition-colors duration-300 p-5 font-medium text-gray-900 dark:text-white">
            <MonthlyCalendar
              date={month}
              availableDates={availableDates}
              onDateClick={(newDay) =>
                setDay(isSameDay(newDay, day, timeZone) ? null : fromZonedTime(newDay, timeZone))
              }
              onNextClick={onNextMonthClick}
              onPrevClick={onPrevMonthClick}
              timeZone={timeZone}
              selectedDate={day}
            />
          </div>
          <div className="grow min-w-[200px] bg-gray-50 dark:bg-gray-800 rounded-lg transition-colors duration-300 p-4 font-medium text-gray-900 dark:text-white flex flex-col justify-center items-center gap-3">
            <div className="grow flex flex-col justify-center gap-6">
              <OrganiserEventsList
                inline={inline}
                domain={domain}
                color={color}
                whitelabel={whitelabel}
                theme={theme}
                events={events}
                noDirectSales={noDirectSales}
                hideBanners={hideBanners}
                widgetId={widgetId}
                thirdParty={thirdParty}
              />{" "}
              {events.length === 0 && (
                <div className="flex justify-center">
                  <Button
                    type="primary"
                    onClick={() => {
                      setDay(null);
                      setMonth(initialMonth);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              )}
            </div>
            {total && total > pageSize ? (
              <Pagination simple pageSize={pageSize} size="xs" total={total} page={page} onChange={setPage} />
            ) : null}
          </div>
        </div>
      ) : null}
      {error || (events && month) ? null : <Spinner />}
    </div>
  );
}
