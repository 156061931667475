import algoliasearch from "algoliasearch/lite";

export const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_PUBLIC_API_KEY);
export default algoliaClient;
export async function performSearch(query, searchOptions = {}, options = {}) {
  const {sponsored = false, order} = options;
  const indexName =
    {
      popularity: "events_by_popularity",
      date: "events_by_date",
      price: "events_by_price",
    }[order] ?? "events";
  if (sponsored > 0) {
    const {results} = await algoliaClient.multipleQueries([
      {
        indexName,
        query,
        params: {clickAnalytics: true, ...searchOptions},
      },
      {
        indexName,
        query,
        params: {
          clickAnalytics: true,
          ...searchOptions,
          hitsPerPage: sponsored,
          filters: [
            searchOptions.filters,
            `org_channel_discovery:true AND promoted:true AND (bucket:"Very low" OR bucket:"Low" OR bucket:"Medium" OR bucket:"High" OR bucket:"Very high")`,
          ]
            .filter(Boolean)
            .join(" AND "),
        },
      },
    ]);
    const [searchResponse, recommendationsResponse] = results;
    const pageOffset = searchResponse.page * searchResponse.hitsPerPage;
    return {
      search: searchResponse.hits.map((hit, idx) => ({
        ...hit,
        id: hit.objectID,
        metadata: {queryID: searchResponse.queryID, index: searchResponse.index, position: pageOffset + idx + 1},
      })),
      recommendations: recommendationsResponse.hits.map((hit, idx) => ({
        ...hit,
        id: hit.objectID,
        metadata: {queryID: recommendationsResponse.queryID, index: recommendationsResponse.index, position: idx + 1},
      })),
      searchResponse,
      recommendationsResponse,
    };
  }
  const index = algoliaClient.initIndex(indexName);
  const searchResponse = await index.search(query, {clickAnalytics: true, ...searchOptions});
  const pageOffset = searchResponse.page * searchResponse.hitsPerPage;
  return {
    search: searchResponse.hits.map((hit, idx) => ({
      ...hit,
      id: hit.objectID,
      metadata: {queryID: searchResponse.queryID, index: searchResponse.index, position: pageOffset + idx + 1},
    })),
    recommendations: [],
    searchResponse,
  };
}
