import {useTranslation} from "react-i18next";

import Button from "~/components/widgets/button";
import {eventUrl} from "~/routes";
import eventDates from "~/utils/eventDates";

export default function OrganiserEventsListItem({
  inline,
  event,
  color,
  hideBanners,
  organization,
  whitelabel,
  noDirectSales,
  theme,
  widgetId,
  thirdParty = false,
}) {
  const {i18n, t} = useTranslation();

  return (
    <div className="w-full flex flex-wrap gap-3 my-2 items-center">
      {!hideBanners && (
        <div className="w-24 shrink-0">
          <img src={event.image} className="w-full rounded" alt={event.name} />
        </div>
      )}
      <div className="grow max-w-xl shrink">
        <h2 className="text-lg leading-tight font-medium text-gray-600 dark:text-gray-100">{event.name}</h2>
        {event.kind === "scheduled" && (
          <span className="py-1 px-2 text-brand-700 dark:text-brand-200 inline-flex ring-1 ring-inset ring-brand-700 dark:ring-brand-200 rounded-xl text-[.625rem] leading-none ">
            {t("new.event.page.recurring.timeslots")}
          </span>
        )}

        <p className="mt-1 p-0 m-0 text-gray-500 font-normal dark:text-gray-400 text-sm">
          {eventDates(event.startsAt, event.endsAt, i18n.language, true, event.timeZone)}
        </p>
        <p className="p-0 m-0 text-gray-500 font-normal dark:text-gray-300 text-sm">{event.address}</p>
      </div>
      <div className="shrink-0 w-full ">
        <Button
          event={event.id}
          theme={theme}
          color={color}
          organization={organization}
          whitelabel={whitelabel}
          hideBanners={hideBanners}
          noDirectSales={noDirectSales}
          inline={inline}
          url={eventUrl({slug: event?.slug, domain: organization, id: event.id})}
          className="w-full justify-center"
          widgetId={widgetId}
          thirdParty={thirdParty}
        />
      </div>
    </div>
  );
}
