import {useTranslation} from "react-i18next";

import OrganiserEventsListItem from "~/components/OrganiserEventsListItem";

export default function OrganiserEventsList({
  inline = false,
  domain,
  hideBanners,
  color,
  whitelabel,
  theme,
  noDirectSales,
  events,
  widgetId,
  thirdParty = false,
}) {
  const {t} = useTranslation();

  return (
    <>
      {events?.map((event) => (
        <OrganiserEventsListItem
          inline={inline}
          key={event.id}
          event={event}
          color={color}
          whitelabel={whitelabel}
          theme={theme}
          hideBanners={hideBanners}
          organization={domain}
          noDirectSales={noDirectSales}
          widgetId={widgetId}
          thirdParty={thirdParty}
        />
      ))}
      {events?.length === 0 ? (
        <div className="text-center text-gray-500 dark:text-gray-400 space-y-4">
          <div className="text-sm font-medium">{t("widget.no_timeslots.notice")}</div>
        </div>
      ) : null}
    </>
  );
}
