import fetchJson from "~/fetchers/fetchJson";
import {deserialize} from "~/utils/jsonapi";
import mkDeepCamelCase from "~/utils/mkDeepCamelCase";

export default async function fetchJsonApi(url) {
  return fetchJson(url).then((json) => ({
    response: deserialize(json),
    links: json.data?.links,
    meta: mkDeepCamelCase(json.meta),
  }));
}
