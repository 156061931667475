import {addMonths, isSameDay} from "date-fns";
import {fromZonedTime, toZonedTime} from "date-fns-tz";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {eventSalesStatusUrl, ticketBuyerSubEventsUrl} from "~/apiRoutes";
import BillettoButton from "~/components/Button";
import ErrorResponse from "~/components/ErrorResponse";
import Pagination from "~/components/Pagination";
import Spinner from "~/components/Spinner";
import Button from "~/components/widgets/button";
import fetchJsonApi from "~/fetchers/fetchJsonApi";
import useBackendData from "~/hooks/useBackendData";
import MonthlyCalendar from "~/MonthlyCalendar";
import eventDates from "~/utils/eventDates";

export function EventButton({
  inline,
  event,
  color,
  organization,
  whitelabel,
  noDirectSales,
  theme,
  thirdParty = false,
}) {
  const {i18n} = useTranslation();
  return (
    <div className="w-full flex flex-wrap  gap-2 items-center mb-8 sm:mb-0">
      <div className="text-gray-500 dark:text-gray-300 font-normal text-sm grow">
        {eventDates(event.startsAt, event.endsAt, i18n.language, true, event.timeZone)}
      </div>
      <div className="shrink-0">
        <Button
          event={event.id}
          theme={theme}
          color={color}
          organization={organization}
          whitelabel={whitelabel}
          inline={inline}
          noDirectSales={noDirectSales}
          className="w-full justify-center"
          thirdParty={thirdParty}
        />
      </div>
    </div>
  );
}

export default function WidgetCalendar({
  inline = false,
  hideBanners,
  noDirectSales,
  domain,
  color,
  whitelabel,
  theme,
  eventId,
  thirdParty,
}) {
  const {
    data: status,
    error: statusError,
    isValidating: loading,
    mutate: refresh,
  } = useBackendData(eventId ? eventSalesStatusUrl({eventId, domain}) : null, {
    fetcher: fetchJsonApi,
  });
  const {t} = useTranslation();
  const {timeZone, initialDate} = status ?? {};
  const initialMonth = useMemo(() => initialDate && toZonedTime(initialDate, timeZone), [initialDate, timeZone]);
  const [month, setMonth] = useState(initialMonth);
  const [day, setDay] = useState(null);
  const [page, setPage] = useState(1);
  const [nearestMonth, setNearestMonth] = useState(month);
  const query = useMemo(() => {
    return {
      "filter[date]": month?.toISOString(),
      "filter[day]": day?.toISOString(),
      "page[size]": "5",
      "page[number]": page.toString(),
    };
  }, [day, month, page]);
  const {
    data: events,
    isValidating,
    meta,
    error,
  } = useBackendData(status ? ticketBuyerSubEventsUrl({eventId, query, domain}) : null, {
    fetcher: fetchJsonApi,
    keepPreviousData: true,
  });
  const {pageSize, total, facets} = meta;
  const onNextMonthClick = useCallback(() => {
    setDay(null);
    setMonth(addMonths(month, 1));
  }, [month]);
  const onPrevMonthClick = useCallback(() => {
    setDay(null);
    setMonth(addMonths(month, -1));
  }, [month]);
  const handleReset = useCallback(() => {
    setDay(null);
    setMonth(nearestMonth);
  }, [nearestMonth]);
  const availableDates = useMemo(
    () => facets?.date.map((facet) => toZonedTime(new Date(facet.date), timeZone)) ?? [],
    [facets, timeZone],
  );
  useEffect(() => {
    if (!month && initialMonth) setMonth(initialMonth);
  }, [initialDate, initialMonth, month]);
  useEffect(() => {
    if (!isValidating && month && !day && events?.length) setNearestMonth(month);
  }, [day, month, events, isValidating]);
  useEffect(() => setPage(1), [day, month]);
  if (!month) return <Spinner />;

  return (
    <div className="space-y-4 @container">
      {statusError ? (
        <ErrorResponse error={statusError} loading={loading} onRefresh={refresh} inline={!status} />
      ) : null}
      {status ? (
        <div className="flex flex-wrap @md:flex-nowrap gap-4 rounded-lg justify-center bg-gray-50 dark:bg-gray-800">
          <div className="w-full max-w-[280px] bg-gray-100 dark:bg-gray-700 rounded-lg transition-colors duration-300 p-5 font-medium text-gray-900 dark:text-white">
            <MonthlyCalendar
              date={month}
              availableDates={availableDates}
              onDateClick={(newDay) =>
                setDay(isSameDay(newDay, day, timeZone) ? null : fromZonedTime(newDay, timeZone))
              }
              onNextClick={onNextMonthClick}
              onPrevClick={onPrevMonthClick}
              timeZone={timeZone}
              selectedDate={day}
            />
          </div>
          <div className="grow min-w-[200px] flex flex-col bg-gray-50 dark:bg-gray-800 rounded-lg transition-colors duration-300 gap-3 p-5 font-medium text-gray-900 dark:text-white">
            {events && (
              <h2 className="text-center text-gray-500 dark:text-gray-300 hidden @md:block justify-self-start">
                {t("widget.recurring.select_time")}
              </h2>
            )}
            <div className=" flex flex-col justify-center items-center gap-3 grow">
              {error ? <ErrorResponse inline error={error} /> : null}
              {events?.map((event) => (
                <EventButton
                  inline={inline}
                  key={event.id}
                  event={event}
                  color={color}
                  whitelabel={whitelabel}
                  theme={theme}
                  organization={domain}
                  noDirectSales={noDirectSales}
                  thirdParty={thirdParty}
                />
              ))}
              {events?.length === 0 ? (
                <div className="text-center text-gray-500 dark:text-gray-400 space-y-4">
                  <div className="text-sm font-medium">{t("widget.no_timeslots.notice")}</div>
                  {initialMonth ? (
                    <div className="text-center">
                      <BillettoButton onClick={handleReset} type="primary">
                        {t("widget.no_timeslots.reset")}
                      </BillettoButton>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            {total && total > pageSize ? (
              <Pagination simple pageSize={pageSize} size="xs" total={total} page={page} onChange={setPage} />
            ) : null}
          </div>
        </div>
      ) : null}
      {statusError || status ? null : <Spinner />}
    </div>
  );
}
