import compile from "~/utils/compile";

export const categoriesUrl = compile("/api/categories", {locale: null});
export const typesUrl = compile("/api/types", {locale: null});
export const eventPlanUrl = compile("/api/organizer/events/:eventId/event_plan", {locale: null});
export const eventSalesStatusUrl = compile("/api/events/:eventId/sales_status", {locale: null});
export const eventCalendarUrl = compile("/api/ticket_buyer/events/:id/calendar", {locale: null});
export const plansUrl = compile("/api/plans", {locale: null});
export const recommendationWidgetsUrl = compile("/api/widgets/:placement", {locale: null});
export const organiserOnboardingCardsUrl = compile("/api/organizer/onboarding_cards", {locale: null});
export const organiserDashboardUrl = compile("/api/organizer/dashboard", {locale: null});
export const organiserUpcomingEventsUrl = compile("/api/organizer/upcoming_events", {locale: null});
export const eventTicketBannersUrl = compile("/api/events/:eventId/ticket_banners", {locale: null});
export const organiserUpdatesUrl = compile("/api/organizer/billetto_updates", {locale: null});
export const reportingPayoutUrl = compile("/api/reporting/sales/payout", {locale: null});
export const reportingRevenueUrl = compile("/api/reporting/sales/revenue", {locale: null});
export const reportingTicketsUrl = compile("/api/reporting/sales/tickets", {locale: null});
export const reportingTicketsTimelineUrl = compile("/api/reporting/sales/tickets_timeline", {locale: null});
export const topOrganisersUrl = compile("/api/top_organisers", {locale: null});
export const editorialWidgetsUrl = compile("/api/editorial_widgets/:placement", {locale: null});
export const editorialSearchPageUrl = compile("/api/editorial_search_pages/:slug", {locale: null});
export const organizerTicketsUrl = compile("/api/organizer/tickets/:id?", {locale: null});
export const ticketBuyerTicketsUrl = compile("/api/ticket_buyer/tickets/:id?", {locale: null});
export const ticketUrl = compile("/api/organizer/tickets/:id", {locale: null});
export const ticketRelatedTicketsUrl = compile("/api/organizer/tickets/:id/related_tickets", {locale: null});
export const ticketEventsUrl = compile("/api/organizer/tickets/:id/events", {locale: null});
export const ticketScanLogsUrl = compile("/api/organizer/tickets/:id/scan_logs", {locale: null});
export const ticketSwapUrl = compile("/api/organizer/tickets/:id/swap", {locale: null});
export const eventUrl = compile("/api/events/:eventId?", {locale: null});
export const bankSettingsUrl = compile("/api/bank_settings", {locale: null});
export const eventVenueUrl = compile("/api/events/:eventId/venue", {locale: null});
export const transactionReportSettingsUrl = compile("/api/transaction_report_settings", {locale: null});
export const eventTransactionReportSettingsUrl = compile("/api/events/:eventId/transaction_report_settings", {
  locale: null,
});
export const eventSalesOverviewUrl = compile("/api/events/:eventId/sales_overview", {locale: null});
export const eventTicketSalesBreakdownUrl = compile("/api/events/:eventId/sales_overview/ticket_sales", {locale: null});
export const eventTicketTypesBreakdownUrl = compile("/api/events/:eventId/sales_overview/ticket_types", {locale: null});
export const eventPaymentMethodsBreakdownUrl = compile("/api/events/:eventId/sales_overview/payment_methods", {
  locale: null,
});
export const eventSalesOverTimeUrl = compile("/api/events/:eventId/sales_overview/sales_over_time", {locale: null});
export const eventReferralsOverTimeUrl = compile("/api/events/:eventId/sales_overview/referrals_over_time", {
  locale: null,
});
export const eventSourcesBreakdownUrl = compile("/api/events/:eventId/sales_overview/sources_breakdown", {
  locale: null,
});
export const eventViewSourcesBreakdownUrl = compile("/api/events/:eventId/sales_overview/view_sources_breakdown", {
  locale: null,
});
export const eventMediaBreakdownUrl = compile("/api/events/:eventId/sales_overview/media_breakdown", {locale: null});
export const eventViewMediaBreakdownUrl = compile("/api/events/:eventId/sales_overview/view_media_breakdown", {
  locale: null,
});
export const eventOrdersStatsUrl = compile("/api/events/:eventId/sales_overview/orders_stats", {locale: null});
export const eventSettingsUrl = compile("/api/events/:eventId/settings", {locale: null});
export const relatedEventsUrl = compile("/api/organizer/events/:eventId/related", {locale: null});
export const eventBookingQuestionsUrl = compile("/api/events/:eventId/booking_questions/:id?", {locale: null});
export const eventHeadlinersUrl = compile("/api/events/:eventId/headliners", {locale: null});
export const eventAttendeesUrl = compile("/api/events/:eventId/attendees", {locale: null});
export const imageUploadUrl = compile("/api/images/:id?", {locale: null});
export const paymentMethodsUrl = compile("/api/payment_methods/:id?", {locale: null});
export const eventPaymentMethodsUrl = compile("/api/organizer/events/:eventId/payment_methods/:id?", {locale: null});
export const billettoAdvertisingUrl = compile("/api/ba", {locale: null});
export const eventBillettoAdvertisingUrl = compile("/api/events/:eventId/ba", {locale: null});
export const eventBillettoAdvertisingReportUrl = compile("/api/events/:eventId/ba_report", {locale: null});
export const eventBillettoAdvertisingCurrentUsageUrl = compile("/api/events/:eventId/ba/usage", {locale: null});
export const eventBillettoAdvertisingChannelsUsageUrl = compile("/api/events/:eventId/ba/stats", {locale: null});
export const eventBillettoAdvertisingSalesStatsUrl = compile("/api/events/:eventId/ba/sales_stats", {locale: null});
export const eventBillettoAdvertisingChannelsUrl = compile("/api/events/:eventId/ba/channels/:id?", {locale: null});
export const eventBillettoAdvertisingStatsUrl = compile("/api/organizer/events/:eventId/ba_stats", {locale: null});
export const billettoAdvertisingCurrentUsageUrl = compile("/api/ba/usage", {locale: null});
export const billettoAdvertisingChannelsUsageUrl = compile("/api/ba/stats", {locale: null});
export const billettoAdvertisingEventsUsageUrl = compile("/api/ba/top_events", {locale: null});
export const billettoAdvertisingSalesStatsUrl = compile("/api/ba/sales_stats", {locale: null});
export const billettoAdvertisingChannelsUrl = compile("/api/ba/channels/:id?", {locale: null});
export const billettoAdvertisingStatsUrl = compile("/api/organizer/ba_stats", {locale: null});
export const paymentMethodFeesUrl = compile("/api/payment_method_fees/", {locale: null});

export const eInvoiceSettingsUrl = compile("/api/organizer/einvoice_settings", {locale: null});
export const eInvoicesUrl = compile("/api/organizer/einvoices/:id?", {locale: null});
export const stripeConnectEventsUrl = compile("/api/organizer/stripe_connect_events/:id?", {locale: null});
export const stripeConnectEventUrl = compile("/api/organizer/stripe_connect_events/:eventId?", {locale: null});
export const eventSpaceInsightsUrl = compile("/api/events/:eventId/space_insights", {locale: null});
export const eventSpaceUrl = compile("/api/events/:eventId/spaces/:id", {locale: null});
export const generateEventTicketsUrl = compile("/api/organizer/events/:eventId/tickets/generate", {locale: null});
export const eventPartnersUrl = compile("/api/events/:eventId/partners", {locale: null});
export const serviceOrdersUrl = compile("/api/service_orders/:orderId?", {locale: null});
export const eventGoogleSettingsUrl = compile("/api/events/:eventId/google_universal_settings", {locale: null});
export const eventFacebookPixelSettingsUrl = compile("/api/events/:eventId/facebook_pixel_settings", {locale: null});
export const googleSettingsUrl = compile("/api/organizer/google_universal_settings", {locale: null});
export const facebookPixelSettingsUrl = compile("/api/organizer/facebook_pixel_settings", {locale: null});
export const eventFacebookEventUrl = compile("/api/events/:eventId/facebook_event", {locale: null});
export const eventAccessCodesUrl = compile("/api/events/:eventId/access_codes/:id?", {locale: null});
export const eventTicketTypesUrl = compile("/api/organizer/events/:eventId/ticket_types/:id?", {locale: null});
export const reorderEventTicketTypeUrl = compile("/api/organizer/events/:eventId/ticket_types/:id/move", {
  locale: null,
});
export const reorderEventTicketTypeGroupUrl = compile("/api/organizer/events/:eventId/ticket_type_groups/:id/move", {
  locale: null,
});
export const eventCampaignsUrl = compile("/api/events/:eventId/campaigns/:id?", {locale: null});
export const campaignsUrl = compile("/api/campaigns/:id?", {locale: null});
export const campaignConditionsUrl = compile("/api/campaigns/:campaignId/conditions/:id?", {locale: null});
export const campaignEffectsUrl = compile("/api/campaigns/:campaignId/effects/:id?", {locale: null});
export const campaignTransitionUrl = compile("/api/campaigns/:id/transition", {locale: null});
export const campaignCloneUrl = compile("/api/campaigns/:id/clone", {locale: null});
export const experienceMoreUrl = compile("/api/experience_more", {locale: null});
export const eventTicketSearchUrl = compile("/api/organizer/events/:eventId/tickets/search", {locale: null});
export const eventTicketsImportUrl = compile("/e/:eventId/ticket_imports", {locale: null});
export const eventOfflineScannerExportUrl = compile("/api/events/:eventId/offline_scanner_export", {locale: null});
export const eventTicketsExportUrl = compile("/api/organizer/events/:eventId/tickets/export", {locale: null});
export const organizerOrdersUrl = compile("/api/organizer/orders", {locale: null});
export const organizerEventOrdersUrl = compile("/api/organizer/events/:eventId/orders", {locale: null});
export const organizerExpirableOrdersUrl = compile("/api/organizer/expirable_orders", {locale: null});
export const organizerExpirableEventOrdersUrl = compile("/api/organizer/events/:eventId/expirable_orders", {
  locale: null,
});
export const organizerExpireOrderUrl = compile("/api/organizer/orders/:orderId/expiration", {locale: null});
export const organizerChargeSubscriptionUrl = compile("/api/organizer/orders/:orderId/charge_subscription", {
  locale: null,
});
export const orderDetailsUrl = compile("/api/organizer/orders/:orderId", {locale: null});
export const organizerOrderSubscriptionUrl = compile("/api/organizer/orders/:orderId/subscription", {locale: null});
export const eventOrderUrl = compile("/api/events/:eventId/orders/:orderId/:action?", {locale: null});
export const orderLedgerEntriesUrl = compile("/api/organizer/orders/:orderId/ledger_entries", {locale: null});
export const organizerOrderReceiptsUrl = compile("/api/organizer/orders/:orderId/receipts", {locale: null});
export const organizerOrderNotesUrl = compile("/api/organizer/orders/:orderId/notes", {locale: null});
export const ticketBuyerOrderReceiptsUrl = compile("/api/ticket_buyer/orders/:orderId/receipts", {locale: null});
export const eventWaitlistUrl = compile("/api/events/:eventId/waitlist/:action?", {locale: null});
export const eventWaitlistMembersUrl = compile("/api/events/:eventId/waitlist_members/:memberId?", {locale: null});
export const apiKeyUrl = compile("/api/api_key", {locale: null});
export const identityUrl = compile("/api/identity", {locale: null});
export const eventTillSettingsUrl = compile("/api/organizer/events/:eventId/till_settings", {locale: null});
export const eventSpecialCodeUrl = compile("/api/events/:eventId/special_code", {locale: null});
export const eventScanningSessionsUrl = compile("/api/events/:eventId/scanning_sessions", {locale: null});
export const eventScanningOverviewUrl = compile("/api/events/:eventId/scanning_overview", {locale: null});
export const eventTicketScansUrl = compile("/api/events/:eventId/ticket_scans", {locale: null});
export const completeEventUrl = compile("/api/events/:eventId/complete", {locale: null});
export const linkEventsToMembershipUrl = compile("/api/organizer/subscriptions/:membershipId/events", {locale: null});
export const eventServiceProductsUrl = compile("/api/events/:eventId/service_products/:sku?", {locale: null});
export const promotionRequestUrl = compile("/api/promotion_requests", {locale: null});
export const serviceOrderTransactionsUrl = compile("/api/service_orders/:orderId/transactions", {locale: null});
export const eventTargetGroupsUrl = compile("/api/events/:eventId/target_groups", {locale: null});
export const organizerTargetGroupsUrl = compile("/api/organizer/target_groups", {locale: null});
export const organizerTargetGroupUrl = compile("/api/organizer/target_groups/:id", {locale: null});
export const organizerTargetGroupMemberUrl = compile("/api/organizer/target_groups/:targetGroupId/members/:id?", {
  locale: null,
});
export const eventVenueCategoriesUrl = compile("/api/events/:eventId/venue_categories", {locale: null});
export const ticketHolderUrl = compile("/api/organizer/tickets/:id/holder", {locale: null});
export const organizerEventUrl = compile("/api/organizer/events/:eventId", {locale: null});
export const organizerEventsUrl = compile("/api/organizer/events", {locale: null});
export const organizerEventsCalendarUrl = compile("/api/organizer/events/calendar", {locale: null});
export const organizerTicketBookingQuestionsUrl = compile("/api/organizer/tickets/:id/booking_questions", {
  locale: null,
});
export const organizerOrderBookingQuestionsUrl = compile("/api/organizer/orders/:id/booking_questions", {locale: null});
export const organizerBookingQuestionUrl = compile("/api/organizer/booking_questions/:id", {locale: null});
export const editorialFeaturesUrl = compile("/api/editorial_features", {locale: null});
export const refundsUrl = compile("/api/events/:eventId/refunds", {locale: null});
export const eventTicketsResendUrl = compile("/api/organizer/events/:eventId/tickets/resend", {locale: null});
export const apiKeypairsUrl = compile("/api/keypairs/:id?", {locale: null});
export const eventOnlineEventUrl = compile("/api/events/:eventId/online_events", {locale: null});
export const eventSubEventsUrl = compile("/api/events/:eventId/sub_events/:id?", {locale: null});
export const ticketBuyerSubEventsUrl = compile("/api/ticket_buyer/events/:eventId/sub_events/:id?", {locale: null});
export const ticketBuyerOrganiserEventsUrl = compile("/api/ticket_buyer/organisers/:organiserId/events", {
  locale: null,
});
export const organiserEventSubEventsUrl = compile("/api/organizer/events/:eventId/sub_events", {locale: null});
export const organiserEventOverviewsUrl = compile("/api/organizer/overview/events", {locale: null});
export const eventTicketTypeSaleOverviewsUrl = compile("/api/events/:eventId/ticket_type_sales_overviews", {
  locale: null,
});
export const eventMembershipTypesOverviewUrl = compile("/api/organizer/events/:eventId/membership_types_overview", {
  locale: null,
});
export const campaignApplicationsUrl = compile("/api/campaigns/:campaignId/applications", {locale: null});
export const newsletterSignupsUrl = compile("/api/organizer/newsletter_signups", {locale: null});
export const membershipsUrl = compile("/api/memberships", {locale: null});
export const membershipUrl = compile("/api/memberships/:id/:action?", {locale: null});
export const ticketBuyerMembershipUrl = compile("/api/ticket_buyer/memberships/:id/:action?", {locale: null});
export const membershipInvoicesUrl = compile("/api/memberships/:orderId/invoices/:id?", {locale: null});
export const editorialPagesUrl = compile("/api/editorial_landing_pages/:id?", {locale: null});
export const reportingEventBalanceUrl = compile("/api/reporting/events/:eventId/balance", {locale: null});
export const reportingEventOrdersUrl = compile("/api/reporting/events/:eventId/orders", {locale: null});
export const reportingEventImpressionsUrl = compile("/api/reporting/events/:eventId/impressions", {locale: null});
export const reportingEventImpressionsBreakdownUrl = compile("/api/reporting/events/:eventId/impressions_breakdown", {
  locale: null,
});
export const reportingEventBalanceBreakdownUrl = compile("/api/reporting/events/:eventId/balance_breakdown", {
  locale: null,
});
export const reportingEventOrdersBreakdownUrl = compile("/api/reporting/events/:eventId/orders_breakdown", {
  locale: null,
});
export const orderReservationUrl = compile("/api/orders/:id/reservation", {locale: null});
export const systemNotificationsUrl = compile("/api/system_notifications", {locale: null});
export const systemNotificationUrl = compile("/api/system_notifications/:id/:action?", {locale: null});
export const swapSeatRequestsUrl = compile("/api/swap_seat_requests", {locale: null});
export const ticketBuyerSwapSeatRequestUrl = compile("/api/ticket_buyer/tickets/:ticketId/swap_seat_request", {
  locale: null,
});
export const eventOfferUrl = compile("/api/events/:eventId/offer/:action?", {locale: null});
export const myTicketsUrl = compile("/api/my_tickets", {locale: null});
export const storedCardsUrl = compile("/api/stored_cards/:id?", {locale: null});
export const usersUrl = compile("/api/users/:id?", {locale: null});
export const usersCommunityUrl = compile("/api/users/:id/:scope", {locale: null});
export const eventTicketsUrl = compile("/api/events/:eventId/tickets", {locale: null});
export const ticketBuyerEventTicketsTransferUrl = compile("/api/ticket_buyer/events/:eventId/transfer", {locale: null});
export const addressesUrl = compile("/api/addresses/:id?", {locale: null});
export const userFollowersUrl = compile("/api/users/:id/followers", {locale: null});
export const userFollowingUrl = compile("/api/users/:id/following", {locale: null});
export const organiserSalesOverviewUrl = compile("/api/organizer/overview/sales", {locale: null});
export const employeesUrl = compile("/api/organizer/employees/:id?", {locale: null});
export const webhooksUrl = compile("/api/webhooks/:id?", {locale: null});
export const webhookEventsUrl = compile("/api/webhooks/:webhookId/events/:id?", {locale: null});
export const venuesUrl = compile("/api/organizer/venues/:id?/:action(events|copy)?", {locale: null});
export const eventCalendarsUrl = compile("/api/organizer/event_calendars", {locale: null});
export const ticketBuyerEventsUrl = compile("/api/ticket_buyer/events/:id?", {locale: null});
export const identityEmailPreferencesUrl = compile("/api/identity/email_preferences/:id?", {locale: null});
export const markUserAsOrganizerUrl = compile("/api/users/mark_as_organizer", {locale: null});
export const updateVisitorSessionUrl = compile("/api/users/update_visitor_session", {locale: null});
export const trackOrganizerRegistrationUrl = compile("/api/users/track_organizer_registration", {locale: null});
export const questionsUrl = compile("/api/organizer/questions", {locale: null});
export const eventSetupCardsUrl = compile("/api/organizer/events/:eventId/setup_cards", {locale: null});
export const dashboardCardsUrl = compile("/api/organizer/dashboard_cards", {locale: null});
export const seatsIoCredentialsUrl = compile("/api/organizer/seats_io_credentials", {locale: null});
export const eventSetupProgressUrl = compile("/api/organizer/events/:eventId/setup_cards/progress", {locale: null});
export const orderServiceUrl = compile("/api/orders/:orderId/services/:sku?", {locale: null});
export const organizerStripeConnectUrl = compile("/api/organizer/stripe_connect_credentials/:id?", {locale: null});
export const onboardingUrl = compile("/api/onboarding", {locale: null});
export const eventTicketTypeGroupsUrl = compile("/api/organizer/events/:eventId/ticket_type_groups/:id?", {
  locale: null,
});
export const eventGalleryItemsUrl = compile("/api/organizer/events/:eventId/gallery_items/:id?", {locale: null});
export const eventOnlineItemsUrl = compile("/api/organizer/events/:eventId/online_event_items/:id?", {locale: null});
export const eventSchedulesUrl = compile("/api/organizer/events/:eventId/schedules/:id?", {locale: null});
export const eventScheduleOccurrenceUrl = compile(
  "/api/organizer/events/:eventId/schedules/:scheduleId/occurrences/:id",
  {locale: null},
);
export const cloneEventUrl = compile("/api/organizer/events/:eventId/clone", {locale: null});
export const eventCancellationRequestUrl = compile("/api/organizer/events/:eventId/cancellation_requests", {
  locale: null,
});
export const ticketBuyerEventOffersUrl = compile("/api/ticket_buyer/events/:eventId/offers", {locale: null});
export const ticketBuyerOfferUrl = compile("/api/ticket_buyer/offers/:id", {locale: null});
export const ticketBuyerOrderUrl = compile("/api/ticket_buyer/orders/:id?", {locale: null});
export const ticketBuyerOrderInsuranceUrl = compile("/api/ticket_buyer/orders/:orderId/insurance", {locale: null});
export const ticketBuyerOrderPostalDeliveryUrl = compile("/api/ticket_buyer/orders/:orderId/postal_delivery", {
  locale: null,
});
export const ticketBuyerEventExpressCheckoutUrl = compile("/api/ticket_buyer/events/:eventId/express_checkouts", {
  locale: null,
});
export const mailinglistsUrl = compile("/api/mailinglists", {locale: null});
export const eventWaitingListUrl = compile("/api/organizer/events/:eventId/waiting_list", {locale: null});
export const eventWaitingListMembersUrl = compile("/api/organizer/events/:eventId/waiting_list_members/:id?", {
  locale: null,
});
export const eventWaitingListReportsUrl = compile("/api/organizer/events/:eventId/waiting_list/reports", {
  locale: null,
});
export const eventSavedPaymentMethodsUrl = compile("/api/events/:eventId/payment_methods", {locale: null});
export const ticketBuyerEventPaymentMethodsUrl = compile("/api/ticket_buyer/events/:eventId/payment_methods", {
  locale: null,
});
export const ticketBuyerEventUrl = compile("/api/ticket_buyer/events/:eventId", {locale: null});
export const ticketBuyerWaitingListUrl = compile("/api/ticket_buyer/events/:eventId/waiting_list", {locale: null});
export const ticketBuyerNewsletterSubscriptionUrl = compile("/api/ticket_buyer/newsletter_subscription", {
  locale: null,
});
export const ticketBuyerEventPaymentMethodTypesUrl = compile("/api/ticket_buyer/events/:eventId/payment_method_types", {
  locale: null,
});
export const orderTransactionPaymentMethodsUrl = compile("/api/order_transactions/:id/payment_methods", {locale: null});
export const featureUrl = compile("/api/features/:feature", {locale: null});
export const eventReceiptDetailsUrl = compile("/api/organizer/events/:eventId/receipt_detail", {locale: null});
export const bannerUrl = compile("/api/banner", {locale: null});
export const eventSmartLinksUrl = compile("/api/organizer/events/:eventId/smart_links/:action?", {locale: null});
export const smartLinksUrl = compile("/api/organizer/smart_links/:action?", {locale: null});
export const organizerSalesTrackerAccountsUrl = compile("/api/organizer/sales_tracker_accounts/:id?", {locale: null});

export const referralsUrl = compile("/api/referrals", {locale: null});
export const referralClaimUrl = compile("/api/referrals/:referralId/claim", {locale: null});
export const organizerAccountExportUrl = compile("/api/organizer/overview/account_export", {locale: null});
export const eventBusinessIntelligenceReportsUrl = compile(
  "/api/organizer/events/:eventId/business_intelligence_reports",
  {locale: null},
);
export const businessIntelligenceConfigurationUrl = compile(
  "/api/organizer/business_intelligence_configurations/:type",
  {locale: null},
);
export const noticesUrl = compile("/api/notices", {locale: null});
export const acceptNoticeUrl = compile("/api/notice/accept", {locale: null});
export const organizerApiKeysUrl = compile("/api/api_keys/:id?", {locale: null});
export const organizerTransactionalEmailRejectionsUrl = compile("/api/organizer/transactional_email_rejections/:id?", {
  locale: null,
});
export const rolesUrl = compile("/api/organizer/roles", {locale: null});
export const eventCreativeContentUrl = compile("/api/organizer/events/:eventId/creative_content", {locale: null});
export const locationsUrl = compile("/api/locations", {locale: null});
export const organizerEventFeeUrl = compile("/api/organizer/events/:id/fee", {locale: null});
export const organizerTicketTypeFeeUrl = compile("/api/organizer/ticket_types/:id/fee", {locale: null});
export const identitiesUrl = compile("/api/identities/:id?", {locale: null});
export const eventCreativeHeaderUrl = compile("/api/organizer/events/:eventId/creative_header", {locale: null});
export const eventCreativeDescriptionUrl = compile("/api/organizer/events/:eventId/creative_description", {
  locale: null,
});
export const aiEventDescriptionUrl = compile("/api/organizer/events/:eventId/ai_event_description", {locale: null});
export const attendeesUrl = compile("/api/organizer/attendees", {locale: null});
export const attendeesStatsUrl = compile("/api/organizer/attendees/stats", {locale: null});
export const attendeesReportUrl = compile("/api/organizer/attendees/report", {locale: null});
export const organizerFollowersUrl = compile("/api/organizer/followers", {locale: null});
export const organizerFollowersStatsUrl = compile("/api/organizer/followers/stats", {locale: null});
export const organizerFollowersReportUrl = compile("/api/organizer/followers/report", {locale: null});
export const eventGeoInsightsUrl = compile("/api/events/:eventId/geo_insights", {locale: null});
export const organizerRevenueStatsUrl = compile("/api/organizer/revenue_stats", {locale: null});
export const organizerTopEventsUrl = compile("/api/organizer/top_events", {locale: null});
export const organizerOrdersByStateUrl = compile("/api/organizer/orders_by_state", {locale: null});
export const telemetryUrl = compile("/api/m", {locale: null});
export const videosUrl = compile("/api/videos/:id?", {locale: null});
export const accountSalesReportsUrl = compile("/api/reporting/account_sales_reports", {locale: null});
export const scheduledEventSalesReportsUrl = compile("/api/reporting/events/:eventId/scheduled_event_sales_reports", {
  locale: null,
});
export const priceStatsUrl = compile("/api/price_stats", {locale: null});
export const organizerProductsUrl = compile("/api/organizer/products/:id?", {locale: null});
export const eventProductsUrl = compile("/api/organizer/events/:eventId/products", {locale: null});
export const organizerBillingAddressUrl = compile("/api/organizer/billing_address", {locale: null});
export const organizerEmailListsUrl = compile("/api/organizer/email_lists/:id?", {locale: null});
export const organizerEmailListCampaignsUrl = compile("/api/organizer/email_lists/:listId/email_campaigns/:id?", {
  locale: null,
});
export const organizerEmailCampaignsUrl = compile("/api/organizer/email_campaigns/:id?", {locale: null});
export const emailSystemOrganizerUrl = compile("/api/email_system/organizer", {locale: null});
export const organizerShortMessageListsUrl = compile("/api/organizer/sms_lists/:id?", {locale: null});
export const organizerShortMessageListCampaignsUrl = compile("/api/organizer/sms_lists/:listId/sms_campaigns/:id?", {
  locale: null,
});
export const organizerShortMessageCampaignsUrl = compile("/api/organizer/sms_campaigns/:id?/:action?", {locale: null});
export const shortMessageSystemOrganizerUrl = compile("/api/sms_system/organizer", {locale: null});
export const organizerEventBundlesUrl = compile("/api/organizer/events/:eventId/bundles/:id?", {locale: null});
export const orderPaymentsUrl = compile("/api/orders/:id/payments", {locale: null});
export const orderRedemptionsUrl = compile("/api/orders/:id/redemptions", {locale: null});
export const orderExpressCheckoutUrl = compile("/api/ticket_buyer/orders/:id/express_checkout", {locale: null});
export const localeUrl = compile("/api/locale", {locale: null});
export const organizerWidgetsUrl = compile("/api/organizer/widgets/:action?", {locale: null});
export const taxRatesUrl = compile("/api/organizer/tax_rates", {locale: null});
export const organizerGiftUpCompanyUrl = compile("/api/organizer/gift_up_company", {locale: null});
export const organizerGiftUpItemsUrl = compile("/api/organizer/gift_up_items/:id?", {locale: null});
export const organizersUrl = compile("/api/organizers", {locale: null});
export const ticketBuyersUrl = compile("/api/ticket_buyers", {locale: null});
export const providerUrl = compile("/api/providers/:id", {locale: null});
export const organizerSettingsUrl = compile("/api/organizer/settings", {locale: null});
export const directUploadUrl = compile("/rails/active_storage/direct_uploads", {locale: null});
export const bookingQuestionDirectUploadUrl = compile("/api/organizer/booking_questions/direct_uploads", {
  locale: null,
});
export const ticketBuyerAttendeePhotoDirectUploadUrl = compile("/api/ticket_buyer/attendee_photo_direct_uploads", {
  locale: null,
});
export function blobUrl(blob) {
  return `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
}
