import BillettoGlyph from "~/components/BillettoGlyph";
import classNames from "~/utils/classNames";

export default function Spinner({invert = false, full = false}) {
  return (
    <div className={classNames("w-8 mx-auto py-8", full && "h-screen sm:h-auto flex items-center justify-center")}>
      <BillettoGlyph invert={invert} className="h-8 w-8 rounded-full animate-spin" />
    </div>
  );
}
