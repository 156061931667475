import {compile as compilePath, match} from "path-to-regexp";

import {customNotify} from "~/bugsnag";
import {localeParam} from "~/i18n";

export default function compile(template, defaults = {}) {
  function generateUrl({domain = null, query = {}, ...params} = {}) {
    try {
      const queryParams = new URLSearchParams(query);
      return [
        [
          domain ? `https://${domain}` : null,
          compilePath(template)({
            locale: localeParam,
            ...defaults,
            ...params,
          }),
        ]
          .filter(Boolean)
          .join(""),
        queryParams.toString(),
      ]
        .filter(Boolean)
        .join("?");
    } catch (error) {
      customNotify(error);
      return "";
    }
  }

  generateUrl.template = template;
  generateUrl.match = match(template, {decode: decodeURIComponent});
  return generateUrl;
}
