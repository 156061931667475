import I18n from "~/i18n.js";

class EventLingo {
  constructor(code, i18n = I18n) {
    this.code = code;
    this.i18n = i18n;
  }

  t(key, options = {}) {
    const keyWithCode = [this.code, key].filter((key) => key).join(".");
    if (this.i18n.exists(keyWithCode, options))
      return this.i18n.t(keyWithCode, options);
    return this.i18n.t(key, options);
  }

  get language() {
    return this.i18n.language;
  }
}

const Lingo = (code) => {
  return new EventLingo(code);
};
export default Lingo;
