import compile from "~/utils/compile";
import template from "~/utils/template";

export const rootUrl = compile(template(""));
export const searchUrl = compile(template("search"));
export const ticketBuyerUrl = compile(template("ticket_buyer"));
export const myTicketsUrl = compile(template("ticket_buyer", "tickets", ":filter?"));
export const eventTicketsUrl = compile(template("ticket_buyer/tickets/events/:eventId"));
export const accountUrl = compile(template("ticket_buyer", "account"));
export const myDataUrl = compile(template("ticket_buyer", "my_data", ":page?", ":id?"));
export const mySubscriptionsUrl = compile(template("ticket_buyer", "subscriptions", ":id?"));
export const signInUrl = compile(template("ticket_buyer/sign_in"));
export const signUpUrl = compile(template("ticket_buyer/sign_up"));
export const passwordUrl = compile(template("ticket_buyer/password/:action(edit)?"));
export const eventUrl = compile(template("e", ":slug([a-zA-Z0-9_-]+)-:id", ":action?"));
export const pageUrl = compile(template("pages", ":id"));
export const userProfileUrl = compile(template("users", ":id"));
export const userCommunityUrl = compile(template("users", ":id", ":scope(followers|following)", ":page(\\d+)?"));
export const offerUrl = compile(template("ticket_buyer/offers/:id/:action?"));
export const orderUrl = compile(template("ticket_buyer/orders/:id/:action?"));
export const waitingListUrl = compile(template("ticket_buyer", "events", ":eventId", "waiting_list"));
export const widgetEventUrl = compile(template("w/events/:id"));
export const widgetOfferUrl = compile(template("w/offers/:id/:action?"));
export const widgetOrganiserUrl = compile(template("w/organisers/:id"));
export const widgetOrderUrl = compile(template("w/orders/:id/:action?"));
export const receiptDocumentUrl = compile(template("ticket_buyer/receipt/:id"));
