import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const enabledReleaseStages = ["staging", "production"];

Bugsnag.start({
  apiKey: "d1dcfb77bd2d713d2a66e4d9f4385122",
  releaseStage: RAILS_ENV,
  redactedKeys: ["password", "token", "api_token", "access_token"],
  onError: (event) => {
    if (window.bootstrap) {
      const {user, identity, ticket_buyer} = window.bootstrap;
      event.setUser(user?.id);
      event.addMetadata("user", {
        id: user && user.id,
        identity_id: identity && identity.id,
        ticket_buyer_id: ticket_buyer && ticket_buyer.id,
      });
    }
  },
  plugins: [new BugsnagPluginReact()],
  appVersion: VERSION,
});

export default Bugsnag;

export const customNotify = (error, opts, cb) => {
  if (!enabledReleaseStages.includes(RAILS_ENV)) {
    console.error(error); // eslint-disable-line
  }
  Bugsnag.notify(
    error,
    (event) => {
      if (opts) event.addMetadata("context", opts);
    },
    cb,
  );
};
