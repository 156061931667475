import {formatInTimeZone} from "date-fns-tz";

export default function eventDates(
  startsAt,
  endsAt,
  locale,
  includeYear = false,
  timeZone,
) {
  timeZone ??= Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!startsAt || !endsAt) return "";
  const localeString = locale === "en" ? "en-GB" : locale;
  const startDate = new Date(startsAt).toLocaleDateString(localeString, {
    month: "short",
    day: "numeric",
    year: includeYear ? "numeric" : undefined,
  });
  const startTime = formatInTimeZone(new Date(startsAt), timeZone, "HH:mm");
  const endDate = new Date(endsAt).toLocaleDateString(localeString, {
    month: "short",
    day: "numeric",
    year: includeYear ? "numeric" : undefined,
  });
  const endTime = formatInTimeZone(new Date(endsAt), timeZone, "HH:mm");
  if (startDate === endDate)
    return [[startDate, startTime].join(" "), endTime].join(" - ");
  return [[startDate, startTime].join(" "), [endDate, endTime].join(" ")].join(
    " - "
  );
}
