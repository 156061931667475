export const TYPES = {
  RESIZE: "widget/resize",
  OPEN: "widget/open",
  HIDE: "widget/hide",
  ESCAPE: "widget/escape",
  ORDER_COMPLETED: "widget/orderCompleted",
};

export default class Messenger {
  constructor(window) {
    this._window = window;
    this._handlers = {};
  }

  on(type, callback) {
    if (this._handlers[type]) {
      return;
    }
    const handler = (event) => {
      const {data} = event;
      if (data.type === type) callback(data.payload, event);
    };
    this._window.addEventListener("message", handler);
    this._handlers[type] = handler;
  }

  destroy() {
    for (const type in this._handlers) {
      this._window.removeEventListener("message", this._handlers[type]);
    }
  }

  send(destination, type, payload) {
    const message = {
      type,
      payload,
    };
    if (destination) destination.postMessage(message, "*");
  }
}
