import {CheckIcon} from "@heroicons/react/24/outline";
import {createElement} from "react";

import classNames from "~/utils/classNames";

export default function Result({
  icon = CheckIcon,
  bordered = true,
  status = "default",
  margin = true,
  title,
  children,
  shadow = true,
  className,
}) {
  const iconBackground =
    {
      error: "bg-red-100",
      info: "bg-blue-100",
      success: "bg-green-100",
      warning: "bg-yellow-100",
    }[status] || "bg-gray-100";
  const iconColor =
    {
      error: "text-red-600",
      info: "text-blue-600",
      success: "text-green-600",
      warning: "text-yellow-600",
    }[status] || "text-white";
  return (
    <div
      className={classNames(
        "relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pt-5 pb-4 text-center transition-all w-full max-w-3xl mx-auto sm:p-6",
        margin && "sm:my-8",
        bordered && "border border-gray-200 dark:border-gray-700",
        shadow && "shadow-xl",
        className,
      )}
    >
      <div>
        {icon ? (
          <div
            className={classNames("mx-auto flex h-12 w-12 items-center justify-center rounded-full", iconBackground)}
          >
            {createElement(icon, {className: classNames("h-6 w-6", iconColor), "aria-hidden": "true"})}
          </div>
        ) : null}
        <div className="mt-3 text-center sm:mt-5">
          <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">{title}</h2>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">{children}</div>
    </div>
  );
}
