export default function debug(result, description) {
  try {
    if (window.localStorage?.getItem("DEBUG")) {
      // eslint-disable-next-line
      if (description) console.log(description, result);
      // eslint-disable-next-line
      else console.log(result);
    }
    return result;
  } catch (error) {
    return result;
  }
}
