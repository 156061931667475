import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {eachDayOfInterval, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfMonth, startOfWeek} from "date-fns";
import {format, toZonedTime} from "date-fns-tz";
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";

import classNames from "~/utils/classNames";
import getTimeZone from "~/utils/getTimeZone";

export function DayButton({day, onClick}) {
  const handleClick = useCallback(() => onClick?.(day.date), [day, onClick]);
  if (!day.isCurrentMonth) return <span />;

  return (
    <button
      onClick={handleClick}
      key={day.date.toLocaleDateString()}
      type="button"
      tabIndex={day.isAvailable ? 0 : -1}
      className={classNames(
        "py-1.5 focus:z-10",
        day.isSelected || day.isToday || day.isAvailable ? "font-bold" : "font-normal",
        day.isSelected && "text-gray-900 dark:text-white",
        !day.isSelected && !day.isToday && !day.isAvailable && "text-gray-700 dark:text-gray-300",
        !day.isSelected && !day.isToday && day.isAvailable && "text-brand-700 dark:text-brand-200",
      )}
    >
      <time
        dateTime={day.date}
        className={classNames(
          "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
          day.isSelected && "bg-brand-500 text-brand-secondary-0",
          !day.isSelected && day.isAvailable && "border border-solid border-brand-100 hover:border-transparent",
        )}
      >
        {day.date.getDate()}
      </time>
    </button>
  );
}

export default function MonthlyCalendar({
  date,
  timeZone = getTimeZone(),
  weekStartsOn = 1,
  selectedDate,
  availableDates = [],
  onDateClick,
  onNextClick,
  onPrevClick,
}) {
  const {i18n} = useTranslation();
  const today = useMemo(() => toZonedTime(new Date(), timeZone), [timeZone]);
  const days = useMemo(() => {
    const localizedAvailableDates = availableDates.map((date) => date.toLocaleDateString());

    return eachDayOfInterval({
      start: startOfWeek(startOfMonth(toZonedTime(date || today, timeZone)), {
        weekStartsOn,
      }),
      end: endOfWeek(endOfMonth(toZonedTime(date || today, timeZone)), {
        weekStartsOn,
      }),
    }).map((day) => {
      return {
        date: day,
        isCurrentMonth: isSameMonth(day, date),
        isSelected: isSameDay(day, selectedDate),
        isToday: isSameDay(day, today),
        isAvailable: localizedAvailableDates.includes(day.toLocaleDateString()),
      };
    });
  }, [date, today, weekStartsOn, availableDates, selectedDate, timeZone]);
  const dayNames = useMemo(
    () => [
      days[0].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
      days[1].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
      days[2].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
      days[3].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
      days[4].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
      days[5].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
      days[6].date.toLocaleDateString(i18n.language, {weekday: "short"})[0],
    ],
    [days, i18n.language],
  );
  const monthName = useMemo(() => date.toLocaleDateString(i18n.language, {month: "long"}), [date, i18n.language]);

  return (
    <div>
      <div className="flex items-center text-center text-gray-900 dark:text-gray-300">
        <button
          type="button"
          onClick={onPrevClick}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-900 dark:text-gray-50 hover:text-gray-500 dark:hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto font-medium text-gray-600 dark:text-gray-300">
          {monthName} {format(date, "yyyy", {timeZone})}
        </div>
        <button
          type="button"
          onClick={onNextClick}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-900 dark:text-gray-50 hover:text-gray-500 dark:hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        {dayNames.map((name, idx) => (
          <div key={`${name}-${idx}`} className="uppercase">
            {name}
          </div>
        ))}
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px text-sm">
        {days.map((day) => (
          <DayButton day={day} key={day.date} onClick={onDateClick} />
        ))}
      </div>
    </div>
  );
}
