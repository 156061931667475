import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {performSearch} from "~/algoliaClient";
import Button from "~/components/Button";
import BillettoLogo from "~/icons/BillettoLogo";
import eventDates from "~/utils/eventDates";
import tagUrl from "~/utils/tagUrl";

export function EventRow({event, tags}) {
  const {i18n, t} = useTranslation();

  return (
    <div className="w-full flex flex-wrap gap-3 my-2 items-center">
      <div className="w-24 shrink-0">
        <img src={event.image} className="w-full rounded" alt={event.name} />
      </div>
      <div className="grow shrink">
        <h2 className="text-lg leading-tight font-medium text-gray-600 dark:text-gray-100">{event.name}</h2>
        {event.kind === "scheduled" && (
          <span className="py-1 px-2 text-brand-700 dark:text-brand-200 inline-flex ring-1 ring-inset ring-brand-700 dark:ring-brand-200 rounded-xl text-[.625rem] leading-none ">
            {t("new.event.page.recurring.timeslots")}
          </span>
        )}
        <p className="mt-1 p-0 m-0 text-gray-500 font-normal dark:text-gray-400 text-sm">
          {eventDates(event.startsAt, event.endsAt, i18n.language, true, event.timeZone)}
        </p>
        <p className="p-0 m-0 text-gray-500 font-normal dark:text-gray-300 text-sm">{event.location}</p>
      </div>
      <div className="shrink-0 grow sm:grow-0 sm:w-32">
        <Button
          as="a"
          href={tagUrl(event.url, tags)}
          type="primary"
          className="text-center"
          block
          target="_blank"
          rel="noopener"
        >
          {t("ticket.buy_tickets_button_text")}
        </Button>
      </div>
    </div>
  );
}

export default function Events({color, theme, searchOptions, tags, query}) {
  const [events, setEvents] = useState(null);
  useEffect(() => {
    performSearch(query ?? "", searchOptions).then(({search}) => setEvents(search));
  }, [searchOptions]);

  if (!events) return null;

  return (
    <div className="space-y-1 grow">
      <div className="space-y-4 rounded-md">
        <div className="flex flex-wrap sm:flex-nowrap gap-4 justify-center rounded-lg bg-gray-50 dark:bg-gray-800">
          <div className="grow min-w-[200px] bg-gray-50 dark:bg-gray-800 rounded-lg transition-colors duration-300 p-5 font-medium text-gray-900 dark:text-white flex flex-col justify-center items-center gap-8 sm:gap-3">
            {events.map((event) => (
              <EventRow key={event.id} event={event} color={color} theme={theme} tags={tags} />
            ))}
          </div>
        </div>
      </div>
      <div className="text-right text-gray-900 dark:text-white">
        <BillettoLogo className="inline h-4 w-auto" />
      </div>
    </div>
  );
}
