import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";

import Button from "~/components/Button";
import Select from "~/components/Select";
import classNames from "~/utils/classNames";

function sizeStyles(size) {
  return (
    {
      xs: "px-2.5 py-1.5 text-xs",
      sm: "px-3 py-2 leading-4 text-sm",
      lg: "px-4 py-2 text-base",
      xl: "px-6 py-4 text-base",
    }[size] || "px-4 py-2 text-sm"
  );
}

function PageButton({size, simple, page, pageSize, onChange, current = false}) {
  const handleClick = useCallback(() => onChange?.(page, pageSize), [onChange, page, pageSize]);

  return (
    <Button
      grouped
      size={size}
      type={current ? "light" : "default"}
      raw={simple}
      onClick={current ? null : handleClick}
      aria-current={current ? "page" : null}
      className={classNames(
        simple ? "px-4 py-2" : null,
        current ? "font-medium z-10" : null,
        simple ? sizeStyles(size) : null
      )}
    >
      {page}
    </Button>
  );
}

export default function Pagination({
  page = 1,
  pageSize = 25,
  total,
  onChange,
  allowSizeChange = false,
  summary = false,
  size,
  simple = false,
}) {
  const {t} = useTranslation();
  const from = useMemo(() => 1 + (page - 1) * pageSize, [page, pageSize]);
  const to = useMemo(() => (page * pageSize > total ? total : page * pageSize), [pageSize, page, total]);
  const lastPage = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);
  const pageSizeOptions = useMemo(() => {
    const options = [
      {value: 10, label: [10, t("pagination.items_per_page")].join(" ")},
      {value: 25, label: [25, t("pagination.items_per_page")].join(" ")},
      {value: 50, label: [50, t("pagination.items_per_page")].join(" ")},
      {value: 100, label: [100, t("pagination.items_per_page")].join(" ")},
    ];
    if (pageSize && !isNaN(Number(pageSize)) && ![10, 25, 50, 100].includes(Number(pageSize)))
      options.push({value: Number(pageSize), label: [pageSize, t("pagination.items_per_page")].join(" ")});
    return options.sort((x, y) => x.value - y.value);
  }, [pageSize]);
  const handleSizeChange = useCallback((event) => onChange?.(1, event.target.value), [onChange]);
  const handlePrevClick = useCallback(() => {
    if (page > 1) onChange?.(page - 1, pageSize);
  }, [page, pageSize, onChange]);
  const handleNextClick = useCallback(() => {
    if (page < lastPage) onChange?.(page + 1, pageSize);
  }, [lastPage, page, pageSize, onChange]);
  const pages = useMemo(() => {
    if (lastPage <= 7)
      return [...new Array(lastPage)].map((_, idx) => (
        <PageButton
          pageSize={pageSize}
          key={idx}
          simple={simple}
          current={idx + 1 === page}
          size={size}
          onChange={onChange}
          page={idx + 1}
        />
      ));
    if (page - 1 < 5)
      return (
        <>
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={1}
            current={page === 1}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={2}
            current={page === 2}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={3}
            current={page === 3}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={4}
            current={page === 4}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={5}
            current={page === 5}
            onChange={onChange}
            size={size}
          />
          <Button className={simple ? sizeStyles(size) : null} raw={simple} grouped disabled>
            ...
          </Button>
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={lastPage}
            current={page === lastPage}
            onChange={onChange}
            size={size}
          />
        </>
      );
    if (lastPage - page < 4)
      return (
        <>
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={1}
            current={page === 1}
            onChange={onChange}
            size={size}
          />
          <Button className={simple ? sizeStyles(size) : null} raw={simple} grouped disabled>
            ...
          </Button>
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={lastPage - 4}
            current={page === lastPage - 4}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={lastPage - 3}
            current={page === lastPage - 3}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={lastPage - 2}
            current={page === lastPage - 2}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={lastPage - 1}
            current={page === lastPage - 1}
            onChange={onChange}
            size={size}
          />
          <PageButton
            pageSize={pageSize}
            simple={simple}
            page={lastPage}
            current={page === lastPage}
            onChange={onChange}
            size={size}
          />
        </>
      );
    return (
      <>
        <PageButton pageSize={pageSize} simple={simple} page={1} current={page === 1} onChange={onChange} size={size} />
        <Button className={simple ? sizeStyles(size) : null} grouped disabled raw={simple}>
          ...
        </Button>
        <PageButton pageSize={pageSize} simple={simple} page={page - 1} onChange={onChange} size={size} />
        <PageButton pageSize={pageSize} simple={simple} page={page} current onChange={onChange} size={size} />
        <PageButton pageSize={pageSize} simple={simple} page={page + 1} onChange={onChange} size={size} />
        <Button className={simple ? sizeStyles(size) : null} grouped disabled raw={simple}>
          ...
        </Button>
        <PageButton
          pageSize={pageSize}
          simple={simple}
          page={lastPage}
          current={page === lastPage}
          onChange={onChange}
          size={size}
        />
      </>
    );
  }, [page, lastPage, onChange, size, simple]);

  if (!total) return null;

  return (
    <div className="flex items-center justify-between px-4 py-3 sm:px-6 overflow-auto">
      <div className="flex flex-1 gap-4 justify-between sm:hidden">
        <div>
          {page !== 1 ? (
            <Button size={size} onClick={handlePrevClick}>
              {t("defaults.previous")}
            </Button>
          ) : null}
        </div>
        <div>
          {page !== lastPage ? (
            <Button size={size} onClick={handleNextClick}>
              {t("defaults.next")}
            </Button>
          ) : null}
        </div>
      </div>
      <div
        className={classNames(
          "hidden sm:flex sm:flex-1 sm:items-center",
          summary ? "sm:justify-between" : "sm:justify-around"
        )}
      >
        {summary ? (
          <div>
            <p className="text-sm text-gray-700 dark:text-gray-300">
              {t("pagination.range_and_total", {from, to, total})}
            </p>
          </div>
        ) : null}
        <div className="flex items-center gap-2">
          <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
            <Button
              grouped
              size={size}
              onClick={handlePrevClick}
              disabled={page === 1}
              icon={ChevronLeftIcon}
              aria-label={t("defaults.previous")}
            />
            {pages}
            <Button
              grouped
              size={size}
              onClick={handleNextClick}
              disabled={page === lastPage}
              icon={ChevronRightIcon}
              aria-label={t("defaults.next")}
            />
          </nav>
          {allowSizeChange ? (
            <Select native size={size} onChange={handleSizeChange} value={pageSize} options={pageSizeOptions} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
