export function getCSRFToken() {
  const node = document.querySelector("meta[name=csrf-token]");
  return (node && node.getAttribute("content")) || "";
}

export function setCSRFToken(token) {
  const node = document.querySelector("meta[name=csrf-token]");
  if (token && node)
    node.setAttribute("content", token);
}
