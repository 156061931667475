import {get} from "~/fetch";

export default async function fetchJsonApi(url) {
  const res = await get(url);
  if (!res.ok) {
    const error = new Error();
    error.status = res.status;
    if (/json/.test(res.headers.get("Content-Type"))) {
      try {
        const response = await res.json();
        error.message = response.errors?.map((e) => e.title).join(", ");
        error.info = response;
      } catch (_err) {
        error.info = {};
      }
    }
    throw error;
  }
  return res.json();
}
