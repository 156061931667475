import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import Btn from "~/components/Button";
import Messenger, {TYPES} from "~/embeddable/Messenger";
import {widgetOrganiserUrl} from "~/ticket_buyer/routes";

export default function OrganiserButton({
  hideBanners,
  organiserId,
  color,
  organization,
  whitelabel,
  includePrivate,
  theme,
  noDirectSales,
  widgetId,
}) {
  const {ready, t, i18n} = useTranslation();
  const iframeRef = useRef();
  const iframeUrl = useMemo(() => {
    const query = {
      theme,
      organization,
      color,
      locale: i18n.language,
      wid: widgetId,
      utm_source: "Billetto",
      utm_medium: "Widget",
      utm_campaign: "Organiser site",
      utm_content: window.location?.toString(),
    };
    if (whitelabel) query.whitelabel = true;
    if (hideBanners) query["hide-banners"] = true;
    if (includePrivate) query["include-private"] = true;
    if (noDirectSales) query["no-direct-sales"] = true;
    return widgetOrganiserUrl({
      id: organiserId,
      domain: organization,
      query,
    });
  }, [organiserId, theme, organization, color, hideBanners, whitelabel]);

  const [show, setShow] = useState(false);
  const hideOverlay = useCallback(
    (_, event) => {
      if (new RegExp(`https?://${organization}$`).test(event.origin)) {
        setShow(false);
        const iframeNode = iframeRef.current;
        if (iframeNode && document.body.contains(iframeNode)) document.body.removeChild(iframeNode);
      }
    },
    [organization],
  );
  const handleRedirect = useCallback(
    (url, event) => {
      if (new RegExp(`https?://${organization}$`).test(event.origin)) window.location.href = url;
    },
    [organization],
  );
  const handleOpen = useCallback(() => {
    setShow(true);
    const iframeNode = document.createElement("iframe");
    iframeNode.setAttribute("allow", "payment");
    iframeNode.setAttribute("allowFullScreen", "");
    iframeNode.setAttribute("src", iframeUrl);
    iframeNode.style.position = "fixed";
    iframeNode.style.top = "0px";
    iframeNode.style.left = "0px";
    iframeNode.style.margin = "0";
    iframeNode.style.padding = "0";
    iframeNode.style.border = "none";
    iframeNode.style.width = "100vw";
    iframeNode.style.height = "100dvh";
    iframeNode.style.zIndex = "2147483647";
    iframeNode.style.backgroundColor = "rgba(0,0,0,0.25)";
    if (iframeRef.current && document.body.contains(iframeRef.current)) {
      document.body.replaceChild(iframeNode, iframeRef.current);
    } else document.body.appendChild(iframeNode);
    iframeRef.current = iframeNode;
  }, [iframeUrl]);

  useEffect(() => {
    const messenger = new Messenger(window);
    messenger.on(TYPES.HIDE, hideOverlay);
    messenger.on(TYPES.ESCAPE, handleRedirect);
    return () => {
      messenger.destroy();
    };
  }, [hideOverlay]);

  if (!organiserId || !organization || !ready) return null;

  return (
    <>
      <Btn onClick={handleOpen} loading={!ready} type="primary" disabled={show}>
        {t("ticket.buy_tickets_button_text")}
      </Btn>
    </>
  );
}
