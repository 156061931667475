import "whatwg-fetch";

import {setDefaultOptions} from "date-fns";
import {da, de, enGB as en, es, fi, fr, it, nb as no, nl, pt, sv} from "date-fns/locale";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const metaLocale = document.querySelector("meta[property=locale]");
let locale = metaLocale && metaLocale.content && metaLocale.content.split("-")[0];
const defaultMetaLocale = document.querySelector("meta[property=default-locale]");
export const defaultLocale = defaultMetaLocale?.content;
export let localeParam;
export let languagePrefix = "";

let dateFnsLocale;
export function dateLocale() {
  return dateFnsLocale;
}

function updateLocaleParams() {
  locale = i18n.language;
  localeParam = locale === defaultLocale || !defaultLocale ? undefined : locale;
  languagePrefix = !localeParam || locale === defaultLocale ? "" : `/${localeParam}`;
}

export async function resolveDateFnsLocale() {
  const importer = {da, de, en, es, fi, fr, it, no, nl, pt, sv}[i18n.language];
  if (importer) {
    dateFnsLocale = importer;
    setDefaultOptions({locale: dateFnsLocale});
  }
}

i18n.on("initialized", resolveDateFnsLocale);
i18n.on("initialized", updateLocaleParams);
i18n.on("languageChanged", resolveDateFnsLocale);
i18n.on("languageChanged", updateLocaleParams);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${ASSETS_PREFIX}/locales/%{lng}.locale.json`,
    },
    compatibilityJSON: "v3",
    fallbackLng: "en",
    lng: locale,
    interpolation: {
      prefix: "%{",
      suffix: "}",
    },
    saveMissing: false,
    react: {
      useSuspense: false,
    },
  });

export const i18nPromise = new Promise((resolve) => {
  let called = false;
  if (i18n.isInitialized) {
    called = true;
    resolve(i18n.language, i18n);
  } else {
    const initialized = () => {
      setTimeout(() => i18n.off("initialized", initialized));
      if (!called) {
        called = true;
        resolve(i18n.language, i18n);
      }
    };
    i18n.on("initialized", initialized);
  }
});
export const t = (key, options) => (i18n.isInitialized ? i18n.t.bind(i18n)(key, options) : "");
export const exists = i18n.exists.bind(i18n);
export default i18n;

export const languagesList = [
  {
    code: "en",
    name: "English",
    icon: "🇬🇧",
  },
  {
    code: "da",
    name: "Dansk",
    icon: "🇩🇰",
  },
  {
    code: "de",
    name: "Deutsch",
    icon: "🇩🇪",
  },
  {
    code: "es",
    name: "Español",
    icon: "🇪🇸",
  },
  {
    code: "fi",
    name: "Suomi",
    icon: "🇫🇮",
  },
  {
    code: "fr",
    name: "Français",
    icon: "🇫🇷",
  },
  {
    code: "it",
    name: "Italiano",
    icon: "🇮🇹",
  },
  {
    code: "nl",
    name: "Nederlands",
    icon: "🇳🇱",
  },
  {
    code: "no",
    name: "Norsk",
    icon: "🇳🇴",
  },
  {
    code: "pt",
    name: "Português",
    icon: "🇵🇹",
  },
  {
    code: "ru",
    name: "Русский",
    icon: "🇷🇺",
  },
  {
    code: "sv",
    name: "Svenska",
    icon: "🇸🇪",
  },
];
