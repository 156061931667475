import {CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";

import classNames from "~/utils/classNames";

export default function Alert({
  bordered = true,
  type = "info",
  banner = false,
  title,
  className,
  children,
  extra,
  icon = true,
  padded = true,
}) {
  const IconComponent =
    icon === true
      ? {
          info: InformationCircleIcon,
          warning: ExclamationTriangleIcon,
          error: XCircleIcon,
          success: CheckCircleIcon,
        }[type] || InformationCircleIcon
      : typeof icon === "function" || typeof icon?.render === "function"
        ? icon
        : () => icon;
  const colors = {
    info: {
      banner: "border-l-4 border-brand-400",
      wrapper: "bg-brand-50",
      icon: "text-brand-400",
      title: "text-brand-800",
      description: "text-brand-700",
    },
    warning: {
      banner: "border-l-4 border-yellow-400",
      wrapper: "bg-yellow-50",
      icon: "text-yellow-400",
      title: "text-yellow-800",
      description: "text-yellow-700",
    },
    error: {
      banner: "border-l-4 border-red-400",
      wrapper: "bg-red-50",
      icon: "text-red-400",
      title: "text-red-800",
      description: "text-red-700",
    },
    success: {
      banner: "border-l-4 border-green-400",
      wrapper: "bg-green-50",
      icon: "text-green-400",
      title: "text-green-800",
      description: "text-green-700",
    },
    default: {
      banner: "border-l-4 border-gray-400",
      wrapper: classNames("bg-gray-50", bordered && "border border-gray-200"),
      icon: "text-gray-400",
      title: "text-gray-800",
      description: "text-gray-700",
    },
  }[type] || {
    banner: "border-l-4 border-brand-400",
    wrapper: "bg-brand-50",
    icon: "text-brand-400",
    title: "text-brand-800",
    description: "text-brand-700",
  };
  return (
    <div className={classNames(padded ? "p-4" : "", colors.wrapper, banner ? colors.banner : "rounded-md", className)}>
      <div className="flex gap-2 items-center">
        {IconComponent ? (
          <div className="shrink-0">
            <IconComponent className={classNames("h-5 w-5", colors.icon)} aria-hidden="true" />
          </div>
        ) : null}
        <div className="flex-1 md:flex md:justify-between md:items-center">
          <div className="space-y-2 w-full">
            {title ? <h3 className={classNames("m-0 text-sm font-medium", colors.title)}>{title}</h3> : null}
            {children && <div className={classNames("text-sm", colors.description)}>{children}</div>}
          </div>
          {extra ? <div className="shrink-0 text-sm md:mt-0 md:ml-6">{extra}</div> : null}
        </div>
      </div>
    </div>
  );
}
