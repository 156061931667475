import {ArrowPathIcon} from "@heroicons/react/20/solid";
import * as React from "react";
import {useTranslation} from "react-i18next";

import Bugsnag from "~/bugsnag";
import Alert from "~/components/Alert";
import Button from "~/components/Button";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function ErrorView({error}) {
  const {t} = useTranslation();
  if (/Loading chunk .* failed/.test(error.message))
    return (
      <div className="text-center">
        <p className="m-0">
          <strong>{t("errors.messages.timeout")}</strong>
        </p>
        <Button icon={ArrowPathIcon} onClick={() => window.location.reload()} type="primary">
          {t("generic.refresh")}
        </Button>
      </div>
    );
  return (
    <Alert type="error" banner title={error.message}>
      <p className="m-0">
        {t("error.spa.refresh")}{" "}
        <Button raw onClick={() => window.location.reload()} icon={ArrowPathIcon} size="xs">
          {t("generic.refresh")}
        </Button>
      </p>
    </Alert>
  );
}

export default ({children, fallback}) => (
  <ErrorBoundary FallbackComponent={fallback || ErrorView}>{children}</ErrorBoundary>
);
