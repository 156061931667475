import {useTranslation} from "react-i18next";

import {ticketBuyerEventUrl} from "~/apiRoutes";
import Card from "~/components/Card";
import ErrorResponse from "~/components/ErrorResponse";
import Spinner from "~/components/Spinner";
import EventCalendar from "~/components/WidgetCalendar";
import fetchJsonApi from "~/fetchers/fetchJsonApi";
import useBackendData from "~/hooks/useBackendData";
import BillettoLogo from "~/icons/BillettoLogo";
import eventDates from "~/utils/eventDates";

import Button from "./button";

export default function Event({
  inline = false,
  event,
  color,
  hideBanners,
  noDirectSales,
  organization,
  whitelabel,
  theme,
  widgetId,
  thirdParty = false,
}) {
  const {i18n, ready} = useTranslation();
  const {data, error, isLoading, isValidating, mutate} = useBackendData(
    ticketBuyerEventUrl({eventId: event, domain: organization}),
    {fetcher: fetchJsonApi},
  );

  if (!ready) return null;

  return (
    <div className="space-y-1 grow @container">
      <Card bordered={!inline} shadow={!inline} background="bg-white dark:bg-gray-900">
        {error ? (
          <ErrorResponse error={error} loading={isValidating} onRefresh={mutate} inline={!data} shadow={false} />
        ) : null}
        {data ? (
          <div className="space-y-4">
            <div className="flex flex-wrap @lg:flex-nowrap gap-4 items-center">
              {!hideBanners && (
                <div className="w-24 shrink-0">
                  <img src={data.image} className="w-full rounded" alt={data.name} />
                </div>
              )}
              <div className="grow">
                <h1 className="text-lg font-medium text-gray-600 dark:text-gray-100">{data.name}</h1>
                <p className="p-0 m-0 text-gray-500 dark:text-gray-400 text-sm">
                  {eventDates(data.startsAt, data.endsAt, i18n.language, true, data.timeZone)}
                </p>
                <p className="p-0 m-0 text-gray-500 dark:text-gray-300 text-sm">{data.address}</p>
              </div>
              <div className="shrink-0 grow">
                {data.kind !== "scheduled" || data.state !== "published" ? (
                  <Button
                    block
                    event={event}
                    color={color}
                    organization={organization}
                    whitelabel={whitelabel}
                    hideBanners={hideBanners}
                    theme={theme}
                    size="xl"
                    noDirectSales={noDirectSales}
                    widgetId={widgetId}
                    thirdParty={thirdParty}
                  />
                ) : null}
              </div>
            </div>
            {data.state === "published" && data.kind === "scheduled" ? (
              <EventCalendar
                inline={inline}
                eventId={data.id}
                domain={organization}
                color={color}
                whitelabel={whitelabel}
                hideBanners={hideBanners}
                noDirectSales={noDirectSales}
                theme={theme}
                thirdParty={thirdParty}
              />
            ) : null}
          </div>
        ) : null}
        {isLoading ? <Spinner /> : null}
      </Card>
      {whitelabel ? null : (
        <div className="text-right text-gray-900 dark:text-white">
          <a href={`https://${organization}`} target="_blank" rel="noreferrer">
            <BillettoLogo className="inline h-4 w-auto" />
          </a>
        </div>
      )}
    </div>
  );
}
