import {ArrowPathIcon, XMarkIcon} from "@heroicons/react/24/outline";
import capitalize from "lodash/capitalize";
import {useTranslation} from "react-i18next";

import Alert from "~/components/Alert";
import Button from "~/components/Button";
import Result from "~/components/Result";

export default function ErrorResponse({error, onRefresh, loading, inline, shadow = true}) {
  const {t} = useTranslation();
  if (!error) return null;

  const errorStatus =
    {
      404: "404",
      403: "403",
      500: "500",
    }[error.status] || "error";
  const defaultErrorMessage =
    {
      404: capitalize(t("errors.messages.not_found")),
    }[errorStatus] || t("generic.error_occurred");

  if (inline)
    return (
      <Alert banner type="error" icon>
        {error.message || defaultErrorMessage}
        {error.status !== 404 && onRefresh ? (
          <Button raw className="ml-2" icon={ArrowPathIcon} onClick={() => onRefresh?.()} loading={loading}>
            {t("generic.refresh")}
          </Button>
        ) : null}
      </Alert>
    );
  return (
    <Result
      icon={XMarkIcon}
      status="error"
      title={error.message || defaultErrorMessage}
      shadow={shadow}
      bordered={shadow}
    >
      {error.status !== 404 && onRefresh ? (
        <Button onClick={() => onRefresh?.()} size="sm" type="primary" loading={loading} icon={ArrowPathIcon}>
          {t("generic.refresh")}
        </Button>
      ) : null}
    </Result>
  );
}
