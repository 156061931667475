export default function inPlaceTransform(transformKeyFn) {
  return function transformKeys(object, processedObjects = new Set()) {
    if (Array.isArray(object)) {
      return object.map((element) => transformKeys(element, processedObjects));
    }
    if (!object || typeof object !== 'object') {
      return object;
    }
    if (processedObjects.has(object)) {
      return object
    }
    const keys = Object.keys(object)
    if (keys.length === 0) {
      return object
    }
    keys.forEach(function (key) {
      object[transformKeyFn(key)] = object[key]
    })
    processedObjects.add(object)
    Object.keys(object).forEach(function (key) {
      transformKeys(object[key], processedObjects)
    })
    return object
  }
}
