export default function BillettoGlyph({
  invert = false,
  currentColor = false,
  className,
}) {
  const colors = [
    invert && currentColor ? "currentColor" : "#1b1d1e",
    !invert && currentColor ? "currentColor" : "#ffffff",
  ];

  return (
    <svg
      className={className}
      enableBackground="new 0 0 50 50"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m50 50v-25h-8.1c0 9.3-7.6 16.9-16.9 16.9s-16.9-7.6-16.9-16.9h-8.1v25z"
        fill={colors[invert ? 1 : 0]}
      />
      <path d="m25 36.4c6.3 0 11.4-5.1 11.4-11.4h-22.8c0 6.3 5.1 11.4 11.4 11.4z" />
      <path
        d="m25 8.1c9.3 0 16.9 7.6 16.9 16.9h8.1v-25h-50v25h8.1c0-9.3 7.6-16.9 16.9-16.9zm0 5.5c-6.3 0-11.4 5.1-11.4 11.4h22.8c0-6.3-5.1-11.4-11.4-11.4zm0 22.8c-6.3 0-11.4-5.1-11.4-11.4h-5.5c0 9.3 7.6 16.9 16.9 16.9s16.9-7.6 16.9-16.9h-5.5c0 6.3-5.1 11.4-11.4 11.4z"
        fill={colors[invert ? 0 : 1]}
      />
      <path
        d="m25 8.1c-9.3 0-16.9 7.6-16.9 16.9h5.5c0-6.3 5.1-11.4 11.4-11.4s11.4 5.1 11.4 11.4h5.5c0-9.3-7.6-16.9-16.9-16.9z"
        fill={colors[invert ? 1 : 0]}
      />
    </svg>
  );
}
