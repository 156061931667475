export default function BillettoLogo(props) {
  return (
    <svg
      {...props}
      fill="currentColor"
      enableBackground="new 0 0 131 50"
      viewBox="0 0 131 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m19.4 19.4c0-7.7-3.9-11-8.7-11-2.2 0-4.1.7-5.6 2.1v-9.6h-5.1v29.1h4.3l.3-2c1.6 1.7 3.7 2.5 6 2.5 4.9-.1 8.8-3.2 8.8-11.1zm-14.4 2.6c-.2-.7-.2-1.6-.2-2.6s.1-1.8.2-2.5c.6-2.9 2.4-4.2 4.5-4.2 2.6 0 4.7 2 4.7 6.7 0 4.8-2.1 6.7-4.7 6.7-2.1.1-3.8-1.1-4.5-4.1zm108.2-13.6c-5.4 0-9.7 3.3-9.7 11 0 7.8 4.4 11 9.7 11 5.4 0 9.7-3.2 9.7-11 .1-7.7-4.3-11-9.7-11zm.1 17.8c-2.6 0-4.7-2-4.7-6.7s2.1-6.7 4.7-6.7 4.7 2 4.7 6.7c0 4.8-2.1 6.7-4.7 6.7zm-72.3-.5c-1 0-1.8-.5-1.8-1.8v-23h-5v23.1c0 .5 0 1 .1 1.5.5 3.3 2.7 4.7 5.3 4.7.8 0 1.6-.1 2.4-.4v-4.2c-.5.1-.8.1-1 .1zm34.3-6.3c0-.7 0-1.4-.1-2-.7-6.2-4.7-9-9.5-9-5.3 0-9.6 3.3-9.6 11 0 7.8 4.3 11 9.6 11 4.1 0 7.6-1.9 8.9-6.4h-5.2c-.9 1.5-2.2 2.1-3.7 2.1-2.3 0-4.2-1.5-4.6-5.1h14.2zm-14.1-2c.5-3.2 2.4-4.7 4.6-4.7s4 1.4 4.6 4.7zm-37.5-8.4h5v25.4h-5zm28.7 16.7c-1 0-1.8-.5-1.8-1.8v-23h-5v23.1c0 .5 0 1 .1 1.5.5 3.3 2.7 4.7 5.3 4.7.8 0 1.6-.1 2.4-.4v-4.2c-.5.1-.8.1-1 .1zm49.3-13.6v-3.7h-4.7v-7.5h-5v7.5h-6.4v-7.5h-5v7.5h-4v3.7h4v11.9c0 .5 0 1 .1 1.5.5 3.3 2.7 4.7 5.3 4.7.8 0 1.6-.1 2.4-.4v-4.2c-.3.1-.6.1-.9.1-1 0-1.8-.5-1.8-1.8v-11.8h6.4v11.9c0 .5 0 1 .1 1.5.5 3.3 2.7 4.7 5.3 4.7.8 0 1.6-.1 2.4-.4v-4.2c-.3.1-.6.1-.9.1-1 0-1.8-.5-1.8-1.8v-11.8z" />
      <circle cx="26.2" cy="3.1" r="3.1" />
      <circle cx="127.4" cy="27.3" r="3.1" />
      <path d="m23.5 45c-5.9 0-10.6-4.8-10.6-10.6h-5c0 8.6 7 15.7 15.7 15.7s15.7-7 15.7-15.7h-5c-.2 5.8-5 10.6-10.8 10.6z" />
    </svg>
  );
}
