import inPlaceDeepCamelCase from "~/utils/inPlaceDeepCamelCase";
import mkDeepCamelCase from "~/utils/mkDeepCamelCase";
import mkDeepSnakeCase from "~/utils/mkDeepSnakeCase";

function cacheKey({id, type}) {
  return [type, id].join("$");
}

export function buildResource(resource, included, lookupCache) {
  const cachedResource = lookupCache[cacheKey(resource)];
  if (cachedResource) return {...cachedResource};
  const result = {
    id: resource.id,
    objectType: resource.type,
    ...resource.attributes,
  };
  if (resource.attributes) lookupCache[cacheKey(resource)] = result;
  if (resource.relationships) {
    Object.keys(resource.relationships).forEach((relation) => {
      const relationship = resource.relationships[relation];
      if (relationship.data) {
        if (Array.isArray(relationship.data))
          result[relation] = relationship.data.map(({id, type}) =>
            buildResource(
              included?.find((res) => res.id === id && res.type === type) || {
                id,
                type,
              },
              included,
              lookupCache,
            ),
          );
        else
          result[relation] = buildResource(
            included?.find((res) => res.id === relationship.data.id && res.type === relationship.data.type) ||
              relationship.data,
            included,
            lookupCache,
          );
      }
    });
  }
  return result;
}

export function deserialize(resource) {
  const cache = {};
  if (!resource.data) return null;
  if (Array.isArray(resource.data))
    return resource.data.map((res) => mkDeepCamelCase(buildResource(res, resource.included, cache)));
  return mkDeepCamelCase(buildResource(resource.data, resource.included, cache));
}

export function deserializeInPlace(resource) {
  const cache = {};
  if (Array.isArray(resource.data))
    return resource.data.map((res) => inPlaceDeepCamelCase(buildResource(res, resource.included, cache)));
  return inPlaceDeepCamelCase(buildResource(resource.data, resource.included, cache));
}

export function serializeResource(
  resource,
  resourceType,
  identifierKey = "id",
  attributesMapper = (attributes) => attributes,
) {
  const {objectType, ...attributes} = resource;
  return {
    id: attributes[identifierKey],
    type: resourceType ? resourceType : objectType,
    attributes: attributesMapper(attributes),
  };
}

export function serialize(resource, resourceType, identifierKey = "id", attributesMapper = (attributes) => attributes) {
  return mkDeepSnakeCase({
    data: Array.isArray(resource)
      ? resource.map((res) => serializeResource(res, resourceType, identifierKey, attributesMapper))
      : serializeResource(resource, resourceType, identifierKey, attributesMapper),
  });
}
