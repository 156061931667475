export default function tagUrl(url, metadata) {
  if (metadata) {
    const targetUrl = new URL(url);
    const params = new URLSearchParams(targetUrl.search);
    if (metadata.utmSource) params.set("utm_source", metadata.utmSource);
    if (metadata.utmMedium) params.set("utm_medium", metadata.utmMedium);
    if (metadata.utmCampaign) params.set("utm_campaign", metadata.utmCampaign);
    if (metadata.utmContent) params.set("utm_content", metadata.utmContent);
    targetUrl.search = params;
    return targetUrl.toString();
  }
  return url;
}
